var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-card-title',[_c('h2',{staticClass:"headline"},[_vm._v("Advisors")]),_c('v-spacer'),_c('v-text-field',{ref:"search",attrs:{"label":"Search","single-line":"","color":_vm.appButtonColor,"clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchTerm.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-striped my-3 advisors",attrs:{"headers":_vm.advisorHeaders,"items":_vm.advisors,"loading":_vm.searchLoading ? _vm.appButtonColor : _vm.searchLoading,"no-data-text":_vm.loadingText,"item-key":"id","options":_vm.options},on:{"click:row":_vm.sendTo,"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.nextAdvisors($event)}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(pageStart === 0 ? 'No Advisors' : (pageStart + "-" + pageStop + " Advisors"))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":item.photo,"target":"_blank","small":""},on:{"click":function($event){$event.stopPropagation();}}},[(item.photo)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-account-off")])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"justify-center py-3"},[_vm._v(" Are you sure? ")]),_c('v-card-actions',{staticClass:"justify-space-around pt-4"},[_c('v-btn',{attrs:{"dark":"","color":_vm.appButtonColor},on:{"click":_vm.removeAdvisor}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"dark":"","color":_vm.appButtonColor},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }