<template>
  <v-container fluid pa-0 grid-list-lg>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <h2 class="headline">Advisors</h2>
            <v-spacer />
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              :color="appButtonColor"
              @keyup.enter="searchTerm"
              ref="search"
              clearable
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
            :headers="advisorHeaders"
            :items="advisors"
            class="elevation-1 table-striped my-3 advisors"
            :loading="searchLoading ? appButtonColor : searchLoading"
            :no-data-text="loadingText"
            item-key="id"
            @click:row="sendTo"
            :options.sync="options"
            @update:page="nextAdvisors($event)"
          >
            <template v-slot:footer.page-text="{ pageStart, pageStop }">
              {{ pageStart === 0 ? 'No Advisors' : `${pageStart}-${pageStop} Advisors` }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template v-slot:item.photo="{ item }">
              <v-btn icon @click.stop="" :href="item.photo" target="_blank" small>
                <v-icon color="green" v-if="item.photo">mdi-account</v-icon>
                <v-icon color="red" v-else>mdi-account-off</v-icon>
              </v-btn>
            </template>
            <!-- <template v-slot:item.delete="{ item }">
              <v-icon 
              @click.stop="confirmRemove(item.id)"
              color="red"
              >
              mdi-delete
            </v-icon>
            </template> -->
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog
        v-model="dialog"
        width="300"
      >
        <v-card class="pa-4">
          <v-card-title class="justify-center py-3">
            Are you sure?
          </v-card-title>
          <v-card-actions class="justify-space-around pt-4">
            <v-btn
              dark
              @click="removeAdvisor"
              :color="appButtonColor"
            >
              Yes
            </v-btn>
            <v-btn
              dark
              @click="dialog = false"
              :color="appButtonColor"
            >
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "dashboard",
  data() {
    return {
      dialog: false,
      replaceLoaded: false,
      page: 1,
      removeId: "",
      options: {
        sortBy: "createdAt"
      },
      ssnSearch: true,
      searchEntered: false,
      searchLoading: false,
      maskState: false,
      searchMask: "###-##-####",
      search: "",
      options: {
        itemsPerPage: 25,
        page: 1
      },
      advisorHeaders: [
        { text: "Created", sortable: true, value: "createdAt" },
        { text: "Last Name", sortable: true, value: "last" },
        { text: "First Name", sortable: true, value: "first" },
        { text: "Salesforce ID", sortable: true, value: "id" },
        { text: "ODS ID", sortable: true, value: "odsId" },
        { text: "Email", sortable: true, value: "email" },
        { text: "Phone", sortable: false, value: "phone" },
        { text: "Photo", sortable: false, value: "photo" },
        // { text: "", sortable: false, value: "delete", align: 'end' }
      ],
    };
  },
  async created() {
    await this.$store.dispatch("user/getAdvisors")
  },
  watch: {
    async img() {
      await this.$store.dispatch("user/saveImage", this.img)
    },
    search(val) {
      if (!val && this.loadedAdvisors.length) {
        this.options.page = 1
        this.$store.commit("user/setLoadedAdvisors", this.loadedAdvisors)
      } 
    }
  },
  computed: {
    ...mapState("user", ["user", "advisors", "nextToken", "loadedAdvisors"]),
    ...mapGetters("user", ["userGroup"]),
    appButtonColor() {
      return this.mersBranded ? "#33715A" : "#0B2A4A";
    },
    totalItems() {
      if (this.nextToken) return this.advisors.length + 1
      else return this.advisors.length
    },
    loadingText() {
      if (!this.search && !this.advisors.length) return "Loading advisors..."
      else return "No advisors found"
    }
  },
  methods: {
    async nextAdvisors(page) {
      let pagesNeeded = this.advisors.length / this.options.itemsPerPage
      if (page === pagesNeeded && this.nextToken && !this.search) {
        this.$store.dispatch("user/getAdvisors", this.nextToken)
      }
    },
    confirmRemove(id) {
      this.dialog = true
      this.removeId = id
    },
    async removeAdvisor() {
      let res = await this.$store.dispatch('user/removeAdvisor', this.removeId)
      this.dialog = false
      if (res) {
        this.displayNotification("success", "Advisor Deleted");
      } else {
        this.displayNotification("error", "Error Deleting Advisor");
      }
    },
    sendTo(val) {
      this.$router.push(`/${val.id}`)
    },
    formatDate(date) {
      let year = date.slice(0, 4);
      let month = date.slice(5, 7);
      let day = date.slice(8, 10);
      return `${month}/${day}/${year}`;
    },
    async displayNotification(type, notification, id) {
      if (type === "success") {
        this.$snotify[type](`${notification}`);
      } 
      else if (type === "confirm") {
        this.$snotify[type](`${notification}`, "", {
          closeOnClick: false,
          position: "centerCenter",
          buttons: [
            {text: 'Yes', action: async () => {
              await this.removeAdvisor(id)
            }, bold: false},
            {text: 'No', action: () => {

            }}
          ]
        })
      }
      else {
        this.$snotify[type](`${notification}`, "", {
          timeout: 0,
        });
      }
    },
    async searchTerm() {
      if (!this.search) return
      if (!this.loadedAdvisors.length) this.replaceLoaded = true
      this.searchLoading = true
      await this.$store.dispatch("user/searchAdvisors", { search: this.search, replace: this.replaceLoaded })
      this.searchLoading = false
      this.replaceLoaded = false
    },
    hideSSN(ssn) {
      if (ssn) {
        return `XXX-XX-${ssn.slice(-4)}`;
      } else return "";
    },
    getItem(item) {
      this.$router.push({
        name: "user",
        params: {
          id: item.username
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list__tile__title {
  font-size: 1.05rem;
}
.no-scroll {
  overflow: hidden;
}
span {
  color: red;
  padding-top: 0.5rem;
  display: inline-block;
}
h3 {
  font-weight: initial;
}
.theme--light .v-list__tile__sub-title {
  color: #40a7c7;
}
// .v-card__title {
//   padding-bottom: 0;
// }
.mers h2.headline {
  font-family: "Ubuntu" !important;
}
.v-input--switch {
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss">
  .remove {
    text-align: center;
  }
  .out-of-sync {
    color: #d11717;
  }
  tr {
    cursor: pointer;
  }
  .advisors {
    .v-data-footer__select {
      display: none;
    }
    .v-data-footer {
      padding: 8px;
      justify-content: end;
    }
    .v-data-footer__pagination{
      font-size: .9rem;
    }
  }
</style>
