import {
    $axios
} from '@/plugins/axios'
import {
    getAdvisor,
    listAdvisors,
    advisorsByLast,
    advisorsByDate
} from "@/graphql/queries"
import {
    createAdvisor,
    deleteAdvisor,
    updateAdvisor
} from "@/graphql/mutations"
import {
    Auth,
    API,
    Storage
} from 'aws-amplify'
import router from '@/router'

const state = {
    user: '',
    state: '',
    advisors: [],
    loadedAdvisors: [],
    advisor: {},
    mersBranded: false,
    newAdvisor: false,
    nextToken: ""
}

const getters = {
    isAuthenticated(state) {
        return !!state.user
    },
//     poolId(state) {
//         return state.user.pool.userPoolId
//     },
    userGroup(state) {
        if (state.user) {
            let group = state.user.signInUserSession.idToken.payload['cognito:groups']
            if (!group) return "User"
            else if (group.includes("SystemAdmin")) return "SystemAdmin"
            else if (group.includes("AdvisorProfile")) return "AdvisorProfile"
            else return "User"
        }
        else return ""
    },
    authorized(state, getters) {
        if (getters.userGroup === "SystemAdmin" || getters.userGroup === "AdvisorProfile") return true
        else return false
    }
//     adminOrSuper(state) {
//         let group = state.user.signInUserSession.idToken.payload['cognito:groups']
//         if (!group) return false
//         else if (group.includes("Admin") || group.includes("Super") || group.includes("SystemAdmin")) return true
//         else return false
//     }
}

const actions = {
//     // async userType({}, user) {
//     //     let group = user.signInUserSession.idToken.payload['cognito:groups']
//     //     if (!group) return "User"
//     //     else if (group.includes("SystemAdmin")) return "SystemAdmin"
//     //     else if (group.includes("Super")) return "Super"
//     //     else if (group.includes("Admin")) return "Admin"
//     //     else return "User"
//     // },
    async saveImage({ commit, state }, { photo, fileName }) {
        let { key } = await Storage.put(fileName, photo, {
            contentType: photo.type
        })
        return await Storage.get(key)
    },
    async checkUser({
        commit,
        dispatch
    }) {
        try {
            let user = await Auth.currentAuthenticatedUser();
            commit("setUser", user);
            return true
        } catch (error) {
            return false
        }
    },
    async getAdvisors({
        state,
        commit
    }, token) {
        try {
            const {
                data: {
                    advisorsByDate: showAdvisors
                }
            } = await API.graphql({
                query: advisorsByDate,
                variables: {
                    type: "Advisor",
                    limit: token ? 25 : 50,
                    sortDirection: "DESC",
                    nextToken: token
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            let { items, nextToken } = showAdvisors
            commit("setAdvisors", items)
            if (nextToken) {
                commit("setToken", nextToken)
            }
            else {
                commit("emptyToken")
            }
        } catch (error) {
            console.log("error getting advisors", error)
        }
    },
    async getAdvisorInfo({
        state,
        commit
    }, id) {
        try {
            const {
                data: {
                    getAdvisor: advisorInfo
                }
            } = await API.graphql({
                query: getAdvisor,
                variables: {id},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            if (advisorInfo) {
                commit("setAdvisor", advisorInfo)
            }
            else {
                throw Error("Advisor does not exist")
            }
        } catch (error) {
            console.log("error getting advisor", error)
            router.push("/")
        }
    },
    async searchAdvisors({
        state,
        commit
    }, { search, replace = false }) {
        if (replace) commit("setLoaded", state.advisors)
        commit("emptyAdvisors")
        try {
            const {
                data: {
                    listAdvisors: advisorsInfo
                }
            } = await API.graphql({
                query: listAdvisors,
                variables: {
                    filter: {
                        or: [
                            {
                                id: {
                                    contains: search
                                }
                            },
                            {
                                first: {
                                    contains: search
                                }
                            },
                            {
                                last: {
                                    contains: search
                                }
                            },
                            {
                                email: {
                                    contains: search
                                }
                            }
                        ]
                    },
                    limit: 3000
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            let { items } = advisorsInfo
            if (items.length) {
                let sorted = items.sort((a, b) => {
                    return a.last > b.last ? 1 : a.last < b.last ? -1 : 0
                })
                commit("setAdvisors", sorted)
                return true
            }
            else return false
        } catch (error) {
            console.log("error getting advisors", error)
        }
    },
    async formatName({ }, email) {
        return `${(email.split("@"))[0]}.jpg`
    },
    async addAdvisor({
        commit,
        dispatch
    }, { id, first, last, phone, email, photo }) {
        if (photo) {
            await dispatch("saveImage", {
                photo,
                fileName: `${id}.jpg`
            })
            photo = `https://d14xc4b7poequf.cloudfront.net/images/${id}.jpg`
        }
        try {
            const {
                data: {
                    createAdvisor: newAdvisor
                }
            } = await API.graphql({
                query: createAdvisor,
                variables: {
                    input: {
                        id,
                        first,
                        last,
                        phone,
                        email,
                        photo
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            if (newAdvisor) {
                commit("addSingleAdvisor", newAdvisor)
                commit("toggleAdvisor", false)
                return true
            }
            else return false
        } catch (error) {
            console.log("error creating advisor", error)
            return false
        }
    },
    async updateAdvisorImage({
        commit
    }, data) {
        try {
            const {
                data: {
                    updateAdvisor: updatedAdvisor
                }
            } = await API.graphql({
                query: updateAdvisor,
                variables: {
                    input: data
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            commit("updateAdvisorInfo", updatedAdvisor)
            return true
        } catch (error) {
            console.log("error updating advisor", error)
            return false
        }
    },
    async updateSingleAdvisor({
        commit,
        dispatch,
        getters
    }, {
            id, first, last, phone, email, photo, designations, jobTitle, 
            registeredAdvisorTitle, acousticPhoto, brandApprovedPhoto, updatedPhoto
        }) {
        if (photo) {
            await dispatch("saveImage", {
                photo,
                fileName: `${id}.jpg`
            })
            photo = `${process.env.VUE_APP_DOMAIN}/images/${id}.jpg`
        }
        try {
            const {
                data: {
                    updateAdvisor: updatedAdvisor
                }
            } = await API.graphql({
                query: updateAdvisor,
                variables: {
                    input: {
                        id,
                        first,
                        last,
                        phone,
                        email,
                        photo,
                        designations,
                        jobTitle,
                        registeredAdvisorTitle,
                        acousticPhoto,
                        brandApprovedPhoto
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            let updatedAdvisorInfo = JSON.parse(JSON.stringify(updatedAdvisor))
            if (updatedPhoto) {
                updatedAdvisor.photo = updatedPhoto
                updatedAdvisorInfo.photo = photo
            }
            commit("setAdvisor", updatedAdvisor)
            commit("updateAdvisorInfo", updatedAdvisorInfo)
            return true
        } catch (error) {
            console.log("error updating advisor", error)
            return false
        }
    },
    async removeAdvisor({
        commit
    }, id) {
        try {
            const {
                data: {
                    deleteAdvisor: removedAdvisor
                }
            } = await API.graphql({
                query: deleteAdvisor,
                variables: {
                    input: {
                        id
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            if (removedAdvisor) {
                commit("removeSingleAdvisor", id)
                return true
            }
            else return false
        } catch (error) {
            console.log("error deleting advisor", error)
            return false
        }
    },
//     async checkUserDetails({
//         state,
//         commit
//     }, userName) {
//         const {
//             data
//         } = await $axios.post(
//             "/", {
//                 query: getUser,
//                 variables: {
//                     username: userName
//                 }
//             }, {
//                 headers: {
//                     Authorization: state.user.signInUserSession.idToken.jwtToken
//                 }
//             }
//         );
//         commit("setUser", user)
//         // let type = await dispatch("userType", user)
//         // if (type === "SystemAdmin") {
//         //     dispatch("checkConfig")
//         // }
//     },
//     async checkConfig({
//         state,
//         commit
//     }) {
//         const {
//             data: {
//                 data
//             }
//         } = await $axios.post(
//             "/", {
//                 query: getConfig
//             }, {
//                 headers: {
//                     Authorization: state.user.signInUserSession.idToken.jwtToken
//                 }
//             }
//         );
//         commit("setConfig", data.getConfig)
//         return data.getConfig
//     },
//     async checkSecureNowPhones({
//         state,
//         commit
//     }, AffectedUserId) {
//         const {
//             data
//         } = await $authUser.post(`/SecureNow/GetPhones`, {
//             AffectedUserId,
//             IsMersCsr: state.mersBranded ? true : false
//         }, {
//             headers: {
//                 Authorization: `Bearer ${
//                 state.user.signInUserSession.idToken.jwtToken
//               }`
//             }
//         });
//         let phones = JSON.parse(data)["Phones"];
//         let allPhones = phones.map(item => {
//             item.PhoneNumber = parsePhoneNumberFromString(item.PhoneNumber, {
//                 defaultCallingCode: item.CountryCode
//             }).number
//             return item
//         })
//         commit("setSNPhones", allPhones)
//     },
//     async removeSecureNowPhone({
//         state,
//         commit
//     }, phone) {
//         const {
//             data
//         } = await $authUser.post(
//             `/SecureNow/DeletePhone`, {
//                 PhoneId: phone.id,
//                 AffectedUserId: phone.user,
//                 IsMersCsr: state.mersBranded ? true : false
//             }, {
//                 headers: {
//                     Authorization: `Bearer ${
//             state.user.signInUserSession.idToken.jwtToken
//           }`
//                 }
//             }
//         );
//         let res = JSON.parse(data).Action
//         if (res === "PhoneDeleted") {
//             commit("removeSNPhone", phone.id)
//             return true
//         } else return false
//     },
//     async updateConfig({
//         state,
//         commit
//     }, config) {
//         let boolean = false
//         if (typeof config === "boolean") {
//             boolean = true
//         }
//         const {
//             data: {
//                 data
//             }
//         } = await $axios.post(
//             "/", {
//                 query: editConfig,
//                 variables: {
//                     message: !boolean ? config : undefined,
//                     maintenance: boolean ? config : undefined
//                 }
//             }, {
//                 headers: {
//                     Authorization: state.user.signInUserSession.idToken.jwtToken
//                 }
//             }
//         );
//         return data.editConfig
//     },
    hostedUILink() {
        const config = Auth.configure();
        const {
            domain,
            redirectSignIn,
            responseType
        } = config.oauth;
        return `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${
          config.userPoolWebClientId}`;
    },
    async login({
        dispatch
    }) {
        window.location.href = await dispatch("hostedUILink")
    },
//     async sendDetails({
//         state
//     }) {
//         const {
//             data
//         } = await $axios.post(
//             "/", {
//                 query: getUserInfo,
//                 variables: {
//                     ssn: this.extraInfo.ssn
//                 }
//             }, {
//                 headers: {
//                     Authorization: this.user.signInUserSession.idToken.jwtToken
//                 }
//             }
//         );

//     }
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setAdvisors(state, advisors) {
        state.advisors = state.advisors.concat(...advisors)
    },
    setAdvisor(state, advisor) {
        state.advisor = advisor
    },
    emptyAdvisor(state) {
        state.advisor = {}
    },
    emptyAdvisors(state) {
        state.advisors = []
    },
    toggleAdvisor(state, val) {
        state.newAdvisor = val
    },
    removeSingleAdvisor(state, id) {
        let ind = state.advisors.findIndex(item => item.id === id)
        state.advisors.splice(ind, 1)
    },
    addSingleAdvisor(state, advisor) {
        state.advisors.push(advisor)
    },
    setToken(state, token) {
        state.nextToken = token
    },
    emptyToken(state) {
        state.nextToken = ""
    },
    setLoaded(state, advisors) {
        state.loadedAdvisors = advisors
    },
    setLoadedAdvisors(state, advisors) {
        state.advisors = advisors
    },
    updateAdvisorInfo(state, advisor) {
        let ind = state.advisors.findIndex(item => item.id === advisor.id)
        if (ind !== -1) state.advisors.splice(ind, 1, advisor)
    },
    emptyUser(state) {
        state.user = ""
    }
//     setUserGroup(state, group) {
//         state.userGroup = group
//     },
//     setUserAtts(state, atts) {
//         state.user.attributes = atts
//     },
//     setState(state, authState) {
//         state.state = authState
//     },
//     setConfirmed(state) {
//         state.confirmed = true
//     },
    
//     unsetConfirmed(state) {
//         state.confirmed = false
//     },
//     setMERSBranded(state, bool) {
//         state.mersBranded = bool;
//     },
//     setSNPhones(state, phones) {
//         state.snPhones = phones
//     },
//     addSNPhone(state, phone) {
//         state.snPhones.push(phone)
//     },
//     removeSNPhone(state, phone) {
//         let id = state.snPhones.findIndex(item => item.PhoneID === phone)
//         state.snPhones.splice(id, 1)
//     },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}