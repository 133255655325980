<template>
  <v-app :class="mersBranded ? 'mers' : 'alerus'">
    <v-app-bar app>
      <v-toolbar-title>
        <router-link to="/">
          <v-img :src="require('@/assets/imgs/alerus-dark.svg')" width="150px" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="user">
        <!-- <v-btn v-if="authorized" @click="$store.commit('user/toggleAdvisor', true)" text :color="appButtonColor">Add New</v-btn> -->
        <!-- <v-btn to="/" text :color="appButtonColor">Advisors</v-btn> -->
        <v-btn @click="logout" text :color="appButtonColor">Sign Out</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn @click="login" text :color="appButtonColor">Sign In</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <!-- <advisor @alert="displayNotification" v-model="newAdvisor" /> -->
        <router-view @alert="displayNotification" />
        <vue-snotify></vue-snotify>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import "@/assets/fonts/fonts.css";
// import Advisor from "@/components/Advisor.vue"
export default {
  name: "App",
  data() {
    return {
    };
  },
  components: {
    // Advisor
  },
  async created() {},
  computed: {
    ...mapState("user", ["confirmed", "user", "state", "mersBranded", "newAdvisor"]),
    ...mapGetters("user", ["userGroup", "authorized"]),
    appHeaderColor() {
      return this.mersBranded ? "#F1F1F1" : "#E9E6E1";
    },
    appButtonColor() {
      return this.mersBranded ? "#33715A" : "#0B2A4A";
    },
  },
  methods: {
    ...mapMutations("user", ["emptyUser", "unsetConfirmed", "setState"]),
    ...mapMutations("accounts", ["setPage", "emptyUserAccount", "setSponsor"]),
    ...mapActions("user", ["login"]),
    displayNotification(type, notification) {
      console.log("alert")
      return
      if (type === "success") {
        this.$snotify[type](`${notification}`);
      } else {
        this.$snotify[type](`${notification}`, "", {
          timeout: 0,
        });
      }
    },
    logout() {
      localStorage.clear();
      this.$store.commit("user/emptyUser")
      if (this.$route.path !== "/") this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  //font-family: "Poppins";
  .alerus {
    font-family: "the-serif";
  }
  .mers {
    font-family: "Ubuntu";
    // font-size: 120%;
    .v-btn__content {
      font-size: 1.1rem;
    }
    .active::before {
      background-color: #33715a;
    }
  }
  margin: 0;
  height: 100%;
}
#app {
  //height: 100%;
}
.active {
  background-color: #d9d7d7;
}
</style>