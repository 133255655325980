import Vue from 'vue'
import Amplify, * as AmplifyModules from 'aws-amplify'
import {
    AmplifyPlugin
} from 'aws-amplify-vue'
import {
    applyPolyfills,
    defineCustomElements,
} from '@aws-amplify/ui-components/loader'

let landing;
if (process.env.NODE_ENV === "development") {
    landing = 'http://localhost:8080/signin'
} else {
    landing = global.location.host.includes("mers") ? process.env.VUE_APP_MERS_REDIRECT_URL : process.env.VUE_APP_REDIRECT_URL
}

Amplify.configure({
    oauth: {
        domain : process.env.VUE_APP_COGNITO_URL,
        scope: ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'],
        redirectSignIn : landing,
        responseType: 'code'
    },
    userPoolId: process.env.VUE_APP_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_POOL_CLIENT_ID,
    region: process.env.VUE_APP_AWS_REGION,
    aws_cognito_identity_pool_id: process.env.VUE_APP_IDENTITY_POOL_ID,
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_APPSYNC_API_URL,
    aws_appsync_region: process.env.VUE_APP_AWS_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_user_files_s3_bucket: process.env.VUE_APP_BUCKET,
    aws_user_files_s3_bucket_region: 'us-east-2'
})

Vue.use(AmplifyPlugin, AmplifyModules)