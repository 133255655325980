/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppConfig = /* GraphQL */ `
  query GetAppConfig($name: String!) {
    getAppConfig(name: $name) {
      name
      env
      sso
      emergency {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      debt {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      insurance {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      retirement {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      hsa {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      createdAt
      updatedAt
      labeling {
        items {
          name
          label
          weight
          emergencySavings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAppConfigs = /* GraphQL */ `
  query ListAppConfigs(
    $name: String
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppConfigs(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        env
        sso
        emergency {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        debt {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        insurance {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        retirement {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        hsa {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        createdAt
        updatedAt
        labeling {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContainerLabel = /* GraphQL */ `
  query GetContainerLabel($name: String!) {
    getContainerLabel(name: $name) {
      name
      label
      weight
      emergencySavings
      createdAt
      updatedAt
      appConfig {
        name
        env
        sso
        emergency {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        debt {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        insurance {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        retirement {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        hsa {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        createdAt
        updatedAt
        labeling {
          nextToken
        }
      }
    }
  }
`;
export const listContainerLabels = /* GraphQL */ `
  query ListContainerLabels(
    $name: String
    $filter: ModelContainerLabelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContainerLabels(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        label
        weight
        emergencySavings
        createdAt
        updatedAt
        appConfig {
          name
          env
          sso
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($name: String!) {
    getNotification(name: $name) {
      name
      start
      stop
      text
      link
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $name: String
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        start
        stop
        text
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      a360id
      accountId
      name
      nickname
      advisorId
      type
      systemId
      systemGroup
      provider
      createdAt
      updatedAt
      workouts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      nextToken
    }
  }
`;
export const accountsByUser = /* GraphQL */ `
  query AccountsByUser(
    $a360id: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByUser(
      a360id: $a360id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      nextToken
    }
  }
`;
export const accountsByAdvisor = /* GraphQL */ `
  query AccountsByAdvisor(
    $advisorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByAdvisor(
      advisorId: $advisorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      nextToken
    }
  }
`;
export const getWorkoutAccount = /* GraphQL */ `
  query GetWorkoutAccount($id: ID!) {
    getWorkoutAccount(id: $id) {
      id
      createdAt
      updatedAt
      account {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const listWorkoutAccounts = /* GraphQL */ `
  query ListWorkoutAccounts(
    $filter: ModelWorkoutAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkoutAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        account {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        workout {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWorkout = /* GraphQL */ `
  query GetWorkout($id: ID!) {
    getWorkout(id: $id) {
      id
      a360id
      type
      score
      monthsIncome
      status
      insuranceTypes {
        type
        status
      }
      dependent
      auto
      house
      rent
      spouseHSA
      spouseHigherHSALimit
      familyPlan
      contribution
      pretax
      afterTax
      employer
      roth
      catchup
      socialSecurityBenefits
      lifeExpectency
      lastTransactionDate
      serviceType
      personalizeGoalRanking {
        ageImportance
        savingsRate
        monthlyIncome
        investmentRiskLoss
      }
      createdAt
      updatedAt
      accounts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      retirementPreferences {
        items {
          id
          name
          minimumSpread
          preferenceId
          preferenceWeight
          acceptableValue
          idealValue
          workoutId
          createdAt
          updatedAt
        }
        nextToken
      }
      pensions {
        items {
          id
          name
          amount
          startAge
          workoutId
          inflation
          isSelected
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listWorkouts = /* GraphQL */ `
  query ListWorkouts(
    $filter: ModelWorkoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const workoutsByUser = /* GraphQL */ `
  query WorkoutsByUser(
    $a360id: String
    $sortDirection: ModelSortDirection
    $filter: ModelWorkoutFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workoutsByUser(
      a360id: $a360id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($a360id: String!) {
    getUser(a360id: $a360id) {
      a360id
      createdAt
      username
      preferredName
      first
      last
      email
      dob
      birthdate
      gender
      maritalStatus
      annualIncome
      dependents
      numberOfDependents
      defaultDashboard
      address1
      address2
      city
      state
      zip
      primary
      allowText
      mers
      userFlags
      cif
      lastReview
      blocked
      defaultMfa
      totpEnabled
      sponsor
      advisorId
      unchallengedLogins
      lastChallenge
      lastLogin
      accountType
      updatedAt
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      workouts {
        items {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      totpDevices {
        items {
          id
          a360id
          name
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      phones {
        items {
          id
          a360id
          countryCode
          number
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $a360id: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      a360id: $a360id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        a360id
        createdAt
        username
        preferredName
        first
        last
        email
        dob
        birthdate
        gender
        maritalStatus
        annualIncome
        dependents
        numberOfDependents
        defaultDashboard
        address1
        address2
        city
        state
        zip
        primary
        allowText
        mers
        userFlags
        cif
        lastReview
        blocked
        defaultMfa
        totpEnabled
        sponsor
        advisorId
        unchallengedLogins
        lastChallenge
        lastLogin
        accountType
        updatedAt
        accounts {
          nextToken
        }
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
        totpDevices {
          nextToken
        }
        phones {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByAdvisor = /* GraphQL */ `
  query UsersByAdvisor(
    $advisorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByAdvisor(
      advisorId: $advisorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        a360id
        createdAt
        username
        preferredName
        first
        last
        email
        dob
        birthdate
        gender
        maritalStatus
        annualIncome
        dependents
        numberOfDependents
        defaultDashboard
        address1
        address2
        city
        state
        zip
        primary
        allowText
        mers
        userFlags
        cif
        lastReview
        blocked
        defaultMfa
        totpEnabled
        sponsor
        advisorId
        unchallengedLogins
        lastChallenge
        lastLogin
        accountType
        updatedAt
        accounts {
          nextToken
        }
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
        totpDevices {
          nextToken
        }
        phones {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        a360id
        createdAt
        username
        preferredName
        first
        last
        email
        dob
        birthdate
        gender
        maritalStatus
        annualIncome
        dependents
        numberOfDependents
        defaultDashboard
        address1
        address2
        city
        state
        zip
        primary
        allowText
        mers
        userFlags
        cif
        lastReview
        blocked
        defaultMfa
        totpEnabled
        sponsor
        advisorId
        unchallengedLogins
        lastChallenge
        lastLogin
        accountType
        updatedAt
        accounts {
          nextToken
        }
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
        totpDevices {
          nextToken
        }
        phones {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRetirementPreference = /* GraphQL */ `
  query GetRetirementPreference($id: ID!) {
    getRetirementPreference(id: $id) {
      id
      name
      minimumSpread
      preferenceId
      preferenceWeight
      acceptableValue
      idealValue
      workoutId
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const listRetirementPreferences = /* GraphQL */ `
  query ListRetirementPreferences(
    $filter: ModelRetirementPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetirementPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        minimumSpread
        preferenceId
        preferenceWeight
        acceptableValue
        idealValue
        workoutId
        createdAt
        updatedAt
        workout {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const preferencesByWorkout = /* GraphQL */ `
  query PreferencesByWorkout(
    $workoutId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRetirementPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByWorkout(
      workoutId: $workoutId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        minimumSpread
        preferenceId
        preferenceWeight
        acceptableValue
        idealValue
        workoutId
        createdAt
        updatedAt
        workout {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPension = /* GraphQL */ `
  query GetPension($id: ID!) {
    getPension(id: $id) {
      id
      name
      amount
      startAge
      workoutId
      inflation
      isSelected
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const listPensions = /* GraphQL */ `
  query ListPensions(
    $filter: ModelPensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPensions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        startAge
        workoutId
        inflation
        isSelected
        createdAt
        updatedAt
        workout {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const pensionsByWorkout = /* GraphQL */ `
  query PensionsByWorkout(
    $workoutId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pensionsByWorkout(
      workoutId: $workoutId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        amount
        startAge
        workoutId
        inflation
        isSelected
        createdAt
        updatedAt
        workout {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAdvisor = /* GraphQL */ `
  query GetAdvisor($id: ID!) {
    getAdvisor(id: $id) {
      id
      odsId
      first
      last
      phone
      email
      designations
      jobTitle
      registeredAdvisorTitle
      photo
      acousticPhoto
      brandApprovedPhoto
      createdAt
      updatedAt
      type
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          a360id
          createdAt
          username
          preferredName
          first
          last
          email
          dob
          birthdate
          gender
          maritalStatus
          annualIncome
          dependents
          numberOfDependents
          defaultDashboard
          address1
          address2
          city
          state
          zip
          primary
          allowText
          mers
          userFlags
          cif
          lastReview
          blocked
          defaultMfa
          totpEnabled
          sponsor
          advisorId
          unchallengedLogins
          lastChallenge
          lastLogin
          accountType
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listAdvisors = /* GraphQL */ `
  query ListAdvisors(
    $filter: ModelAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advisorsByLast = /* GraphQL */ `
  query AdvisorsByLast(
    $type: String
    $last: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advisorsByLast(
      type: $type
      last: $last
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const advisorsByDate = /* GraphQL */ `
  query AdvisorsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advisorsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getChange = /* GraphQL */ `
  query GetChange($id: ID!) {
    getChange(id: $id) {
      id
      createdAt
      a360id
      username
      property
      currentValue
      newValue
      admin
      approved
      approvedBy
      updatedAt
    }
  }
`;
export const listChanges = /* GraphQL */ `
  query ListChanges(
    $filter: ModelChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        a360id
        username
        property
        currentValue
        newValue
        admin
        approved
        approvedBy
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($name: String!) {
    getProvider(name: $name) {
      name
      active
      updatePrefs
      createdAt
      updatedAt
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $name: String
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviders(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        active
        updatePrefs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecret = /* GraphQL */ `
  query GetSecret($id: ID!) {
    getSecret(id: $id) {
      id
      a360id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSecrets = /* GraphQL */ `
  query ListSecrets(
    $filter: ModelSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecrets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        a360id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const secretsByUser = /* GraphQL */ `
  query SecretsByUser(
    $a360id: String
    $sortDirection: ModelSortDirection
    $filter: ModelSecretFilterInput
    $limit: Int
    $nextToken: String
  ) {
    secretsByUser(
      a360id: $a360id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        a360id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhone = /* GraphQL */ `
  query GetPhone($id: ID!) {
    getPhone(id: $id) {
      id
      a360id
      countryCode
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPhones = /* GraphQL */ `
  query ListPhones(
    $filter: ModelPhoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        a360id
        countryCode
        number
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const phonesByUser = /* GraphQL */ `
  query PhonesByUser(
    $a360id: String
    $sortDirection: ModelSortDirection
    $filter: ModelPhoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phonesByUser(
      a360id: $a360id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        a360id
        countryCode
        number
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
