/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppConfig = /* GraphQL */ `
  mutation CreateAppConfig(
    $input: CreateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    createAppConfig(input: $input, condition: $condition) {
      name
      env
      sso
      emergency {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      debt {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      insurance {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      retirement {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      hsa {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      createdAt
      updatedAt
      labeling {
        items {
          name
          label
          weight
          emergencySavings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAppConfig = /* GraphQL */ `
  mutation UpdateAppConfig(
    $input: UpdateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    updateAppConfig(input: $input, condition: $condition) {
      name
      env
      sso
      emergency {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      debt {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      insurance {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      retirement {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      hsa {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      createdAt
      updatedAt
      labeling {
        items {
          name
          label
          weight
          emergencySavings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAppConfig = /* GraphQL */ `
  mutation DeleteAppConfig(
    $input: DeleteAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    deleteAppConfig(input: $input, condition: $condition) {
      name
      env
      sso
      emergency {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      debt {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      insurance {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      retirement {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      hsa {
        containers
        maxScore
        maxScoreWithoutHSA
        video
        funding {
          name
          value
        }
      }
      createdAt
      updatedAt
      labeling {
        items {
          name
          label
          weight
          emergencySavings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createContainerLabel = /* GraphQL */ `
  mutation CreateContainerLabel(
    $input: CreateContainerLabelInput!
    $condition: ModelContainerLabelConditionInput
  ) {
    createContainerLabel(input: $input, condition: $condition) {
      name
      label
      weight
      emergencySavings
      createdAt
      updatedAt
      appConfig {
        name
        env
        sso
        emergency {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        debt {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        insurance {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        retirement {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        hsa {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        createdAt
        updatedAt
        labeling {
          nextToken
        }
      }
    }
  }
`;
export const updateContainerLabel = /* GraphQL */ `
  mutation UpdateContainerLabel(
    $input: UpdateContainerLabelInput!
    $condition: ModelContainerLabelConditionInput
  ) {
    updateContainerLabel(input: $input, condition: $condition) {
      name
      label
      weight
      emergencySavings
      createdAt
      updatedAt
      appConfig {
        name
        env
        sso
        emergency {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        debt {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        insurance {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        retirement {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        hsa {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        createdAt
        updatedAt
        labeling {
          nextToken
        }
      }
    }
  }
`;
export const deleteContainerLabel = /* GraphQL */ `
  mutation DeleteContainerLabel(
    $input: DeleteContainerLabelInput!
    $condition: ModelContainerLabelConditionInput
  ) {
    deleteContainerLabel(input: $input, condition: $condition) {
      name
      label
      weight
      emergencySavings
      createdAt
      updatedAt
      appConfig {
        name
        env
        sso
        emergency {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        debt {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        insurance {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        retirement {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        hsa {
          containers
          maxScore
          maxScoreWithoutHSA
          video
        }
        createdAt
        updatedAt
        labeling {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      name
      start
      stop
      text
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      name
      start
      stop
      text
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      name
      start
      stop
      text
      link
      createdAt
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      a360id
      accountId
      name
      nickname
      advisorId
      type
      systemId
      systemGroup
      provider
      createdAt
      updatedAt
      workouts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      a360id
      accountId
      name
      nickname
      advisorId
      type
      systemId
      systemGroup
      provider
      createdAt
      updatedAt
      workouts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      a360id
      accountId
      name
      nickname
      advisorId
      type
      systemId
      systemGroup
      provider
      createdAt
      updatedAt
      workouts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
    }
  }
`;
export const createWorkoutAccount = /* GraphQL */ `
  mutation CreateWorkoutAccount(
    $input: CreateWorkoutAccountInput!
    $condition: ModelWorkoutAccountConditionInput
  ) {
    createWorkoutAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      account {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const updateWorkoutAccount = /* GraphQL */ `
  mutation UpdateWorkoutAccount(
    $input: UpdateWorkoutAccountInput!
    $condition: ModelWorkoutAccountConditionInput
  ) {
    updateWorkoutAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      account {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const deleteWorkoutAccount = /* GraphQL */ `
  mutation DeleteWorkoutAccount(
    $input: DeleteWorkoutAccountInput!
    $condition: ModelWorkoutAccountConditionInput
  ) {
    deleteWorkoutAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      account {
        id
        a360id
        accountId
        name
        nickname
        advisorId
        type
        systemId
        systemGroup
        provider
        createdAt
        updatedAt
        workouts {
          nextToken
        }
        advisor {
          id
          odsId
          first
          last
          phone
          email
          photo
          createdAt
          updatedAt
          type
        }
      }
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const createWorkout = /* GraphQL */ `
  mutation CreateWorkout(
    $input: CreateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    createWorkout(input: $input, condition: $condition) {
      id
      a360id
      type
      score
      monthsIncome
      status
      insuranceTypes {
        type
        status
      }
      dependent
      auto
      house
      rent
      spouseHSA
      spouseHigherHSALimit
      familyPlan
      contribution
      pretax
      afterTax
      employer
      roth
      catchup
      socialSecurityBenefits
      lifeExpectency
      lastTransactionDate
      serviceType
      personalizeGoalRanking {
        ageImportance
        savingsRate
        monthlyIncome
        investmentRiskLoss
      }
      createdAt
      updatedAt
      accounts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      retirementPreferences {
        items {
          id
          name
          minimumSpread
          preferenceId
          preferenceWeight
          acceptableValue
          idealValue
          workoutId
          createdAt
          updatedAt
        }
        nextToken
      }
      pensions {
        items {
          id
          name
          amount
          startAge
          workoutId
          inflation
          isSelected
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateWorkout = /* GraphQL */ `
  mutation UpdateWorkout(
    $input: UpdateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    updateWorkout(input: $input, condition: $condition) {
      id
      a360id
      type
      score
      monthsIncome
      status
      insuranceTypes {
        type
        status
      }
      dependent
      auto
      house
      rent
      spouseHSA
      spouseHigherHSALimit
      familyPlan
      contribution
      pretax
      afterTax
      employer
      roth
      catchup
      socialSecurityBenefits
      lifeExpectency
      lastTransactionDate
      serviceType
      personalizeGoalRanking {
        ageImportance
        savingsRate
        monthlyIncome
        investmentRiskLoss
      }
      createdAt
      updatedAt
      accounts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      retirementPreferences {
        items {
          id
          name
          minimumSpread
          preferenceId
          preferenceWeight
          acceptableValue
          idealValue
          workoutId
          createdAt
          updatedAt
        }
        nextToken
      }
      pensions {
        items {
          id
          name
          amount
          startAge
          workoutId
          inflation
          isSelected
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteWorkout = /* GraphQL */ `
  mutation DeleteWorkout(
    $input: DeleteWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    deleteWorkout(input: $input, condition: $condition) {
      id
      a360id
      type
      score
      monthsIncome
      status
      insuranceTypes {
        type
        status
      }
      dependent
      auto
      house
      rent
      spouseHSA
      spouseHigherHSALimit
      familyPlan
      contribution
      pretax
      afterTax
      employer
      roth
      catchup
      socialSecurityBenefits
      lifeExpectency
      lastTransactionDate
      serviceType
      personalizeGoalRanking {
        ageImportance
        savingsRate
        monthlyIncome
        investmentRiskLoss
      }
      createdAt
      updatedAt
      accounts {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      retirementPreferences {
        items {
          id
          name
          minimumSpread
          preferenceId
          preferenceWeight
          acceptableValue
          idealValue
          workoutId
          createdAt
          updatedAt
        }
        nextToken
      }
      pensions {
        items {
          id
          name
          amount
          startAge
          workoutId
          inflation
          isSelected
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      a360id
      createdAt
      username
      preferredName
      first
      last
      email
      dob
      birthdate
      gender
      maritalStatus
      annualIncome
      dependents
      numberOfDependents
      defaultDashboard
      address1
      address2
      city
      state
      zip
      primary
      allowText
      mers
      userFlags
      cif
      lastReview
      blocked
      defaultMfa
      totpEnabled
      sponsor
      advisorId
      unchallengedLogins
      lastChallenge
      lastLogin
      accountType
      updatedAt
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      workouts {
        items {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      totpDevices {
        items {
          id
          a360id
          name
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      phones {
        items {
          id
          a360id
          countryCode
          number
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      a360id
      createdAt
      username
      preferredName
      first
      last
      email
      dob
      birthdate
      gender
      maritalStatus
      annualIncome
      dependents
      numberOfDependents
      defaultDashboard
      address1
      address2
      city
      state
      zip
      primary
      allowText
      mers
      userFlags
      cif
      lastReview
      blocked
      defaultMfa
      totpEnabled
      sponsor
      advisorId
      unchallengedLogins
      lastChallenge
      lastLogin
      accountType
      updatedAt
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      workouts {
        items {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      totpDevices {
        items {
          id
          a360id
          name
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      phones {
        items {
          id
          a360id
          countryCode
          number
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      a360id
      createdAt
      username
      preferredName
      first
      last
      email
      dob
      birthdate
      gender
      maritalStatus
      annualIncome
      dependents
      numberOfDependents
      defaultDashboard
      address1
      address2
      city
      state
      zip
      primary
      allowText
      mers
      userFlags
      cif
      lastReview
      blocked
      defaultMfa
      totpEnabled
      sponsor
      advisorId
      unchallengedLogins
      lastChallenge
      lastLogin
      accountType
      updatedAt
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      workouts {
        items {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      totpDevices {
        items {
          id
          a360id
          name
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      phones {
        items {
          id
          a360id
          countryCode
          number
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRetirementPreference = /* GraphQL */ `
  mutation CreateRetirementPreference(
    $input: CreateRetirementPreferenceInput!
    $condition: ModelRetirementPreferenceConditionInput
  ) {
    createRetirementPreference(input: $input, condition: $condition) {
      id
      name
      minimumSpread
      preferenceId
      preferenceWeight
      acceptableValue
      idealValue
      workoutId
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const updateRetirementPreference = /* GraphQL */ `
  mutation UpdateRetirementPreference(
    $input: UpdateRetirementPreferenceInput!
    $condition: ModelRetirementPreferenceConditionInput
  ) {
    updateRetirementPreference(input: $input, condition: $condition) {
      id
      name
      minimumSpread
      preferenceId
      preferenceWeight
      acceptableValue
      idealValue
      workoutId
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const deleteRetirementPreference = /* GraphQL */ `
  mutation DeleteRetirementPreference(
    $input: DeleteRetirementPreferenceInput!
    $condition: ModelRetirementPreferenceConditionInput
  ) {
    deleteRetirementPreference(input: $input, condition: $condition) {
      id
      name
      minimumSpread
      preferenceId
      preferenceWeight
      acceptableValue
      idealValue
      workoutId
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const createPension = /* GraphQL */ `
  mutation CreatePension(
    $input: CreatePensionInput!
    $condition: ModelPensionConditionInput
  ) {
    createPension(input: $input, condition: $condition) {
      id
      name
      amount
      startAge
      workoutId
      inflation
      isSelected
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const updatePension = /* GraphQL */ `
  mutation UpdatePension(
    $input: UpdatePensionInput!
    $condition: ModelPensionConditionInput
  ) {
    updatePension(input: $input, condition: $condition) {
      id
      name
      amount
      startAge
      workoutId
      inflation
      isSelected
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const deletePension = /* GraphQL */ `
  mutation DeletePension(
    $input: DeletePensionInput!
    $condition: ModelPensionConditionInput
  ) {
    deletePension(input: $input, condition: $condition) {
      id
      name
      amount
      startAge
      workoutId
      inflation
      isSelected
      createdAt
      updatedAt
      workout {
        id
        a360id
        type
        score
        monthsIncome
        status
        insuranceTypes {
          type
          status
        }
        dependent
        auto
        house
        rent
        spouseHSA
        spouseHigherHSALimit
        familyPlan
        contribution
        pretax
        afterTax
        employer
        roth
        catchup
        socialSecurityBenefits
        lifeExpectency
        lastTransactionDate
        serviceType
        personalizeGoalRanking {
          ageImportance
          savingsRate
          monthlyIncome
          investmentRiskLoss
        }
        createdAt
        updatedAt
        accounts {
          nextToken
        }
        retirementPreferences {
          nextToken
        }
        pensions {
          nextToken
        }
      }
    }
  }
`;
export const createAdvisor = /* GraphQL */ `
  mutation CreateAdvisor(
    $input: CreateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    createAdvisor(input: $input, condition: $condition) {
      id
      odsId
      first
      last
      phone
      email
      photo
      createdAt
      updatedAt
      type
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          a360id
          createdAt
          username
          preferredName
          first
          last
          email
          dob
          birthdate
          gender
          maritalStatus
          annualIncome
          dependents
          numberOfDependents
          defaultDashboard
          address1
          address2
          city
          state
          zip
          primary
          allowText
          mers
          userFlags
          cif
          lastReview
          blocked
          defaultMfa
          totpEnabled
          sponsor
          advisorId
          unchallengedLogins
          lastChallenge
          lastLogin
          accountType
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAdvisor = /* GraphQL */ `
  mutation UpdateAdvisor(
    $input: UpdateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    updateAdvisor(input: $input, condition: $condition) {
      id
      odsId
      first
      last
      phone
      email
      designations
      jobTitle
      registeredAdvisorTitle
      photo
      acousticPhoto
      brandApprovedPhoto
      createdAt
      updatedAt
      type
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          a360id
          createdAt
          username
          preferredName
          first
          last
          email
          dob
          birthdate
          gender
          maritalStatus
          annualIncome
          dependents
          numberOfDependents
          defaultDashboard
          address1
          address2
          city
          state
          zip
          primary
          allowText
          mers
          userFlags
          cif
          lastReview
          blocked
          defaultMfa
          totpEnabled
          sponsor
          advisorId
          unchallengedLogins
          lastChallenge
          lastLogin
          accountType
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteAdvisor = /* GraphQL */ `
  mutation DeleteAdvisor(
    $input: DeleteAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    deleteAdvisor(input: $input, condition: $condition) {
      id
      odsId
      first
      last
      phone
      email
      photo
      createdAt
      updatedAt
      type
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          a360id
          createdAt
          username
          preferredName
          first
          last
          email
          dob
          birthdate
          gender
          maritalStatus
          annualIncome
          dependents
          numberOfDependents
          defaultDashboard
          address1
          address2
          city
          state
          zip
          primary
          allowText
          mers
          userFlags
          cif
          lastReview
          blocked
          defaultMfa
          totpEnabled
          sponsor
          advisorId
          unchallengedLogins
          lastChallenge
          lastLogin
          accountType
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createChange = /* GraphQL */ `
  mutation CreateChange(
    $input: CreateChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    createChange(input: $input, condition: $condition) {
      id
      createdAt
      a360id
      username
      property
      currentValue
      newValue
      admin
      approved
      approvedBy
      updatedAt
    }
  }
`;
export const updateChange = /* GraphQL */ `
  mutation UpdateChange(
    $input: UpdateChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    updateChange(input: $input, condition: $condition) {
      id
      createdAt
      a360id
      username
      property
      currentValue
      newValue
      admin
      approved
      approvedBy
      updatedAt
    }
  }
`;
export const deleteChange = /* GraphQL */ `
  mutation DeleteChange(
    $input: DeleteChangeInput!
    $condition: ModelChangeConditionInput
  ) {
    deleteChange(input: $input, condition: $condition) {
      id
      createdAt
      a360id
      username
      property
      currentValue
      newValue
      admin
      approved
      approvedBy
      updatedAt
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      name
      active
      updatePrefs
      createdAt
      updatedAt
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      name
      active
      updatePrefs
      createdAt
      updatedAt
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      name
      active
      updatePrefs
      createdAt
      updatedAt
    }
  }
`;
export const editUser = /* GraphQL */ `
  mutation EditUser(
    $props: UpdateUserInput
    $malaInput: String
    $mala: Boolean
  ) {
    editUser(props: $props, malaInput: $malaInput, mala: $mala) {
      a360id
      createdAt
      username
      preferredName
      first
      last
      email
      dob
      birthdate
      gender
      maritalStatus
      annualIncome
      dependents
      numberOfDependents
      defaultDashboard
      address1
      address2
      city
      state
      zip
      primary
      allowText
      mers
      userFlags
      cif
      lastReview
      blocked
      defaultMfa
      totpEnabled
      sponsor
      advisorId
      unchallengedLogins
      lastChallenge
      lastLogin
      accountType
      updatedAt
      accounts {
        items {
          id
          a360id
          accountId
          name
          nickname
          advisorId
          type
          systemId
          systemGroup
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      workouts {
        items {
          id
          a360id
          type
          score
          monthsIncome
          status
          dependent
          auto
          house
          rent
          spouseHSA
          spouseHigherHSALimit
          familyPlan
          contribution
          pretax
          afterTax
          employer
          roth
          catchup
          socialSecurityBenefits
          lifeExpectency
          lastTransactionDate
          serviceType
          createdAt
          updatedAt
        }
        nextToken
      }
      advisor {
        id
        odsId
        first
        last
        phone
        email
        photo
        createdAt
        updatedAt
        type
        accounts {
          nextToken
        }
        users {
          nextToken
        }
      }
      totpDevices {
        items {
          id
          a360id
          name
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      phones {
        items {
          id
          a360id
          countryCode
          number
          type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSecret = /* GraphQL */ `
  mutation CreateSecret(
    $input: CreateSecretInput!
    $condition: ModelSecretConditionInput
  ) {
    createSecret(input: $input, condition: $condition) {
      id
      a360id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSecret = /* GraphQL */ `
  mutation UpdateSecret(
    $input: UpdateSecretInput!
    $condition: ModelSecretConditionInput
  ) {
    updateSecret(input: $input, condition: $condition) {
      id
      a360id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecret = /* GraphQL */ `
  mutation DeleteSecret(
    $input: DeleteSecretInput!
    $condition: ModelSecretConditionInput
  ) {
    deleteSecret(input: $input, condition: $condition) {
      id
      a360id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const createPhone = /* GraphQL */ `
  mutation CreatePhone(
    $input: CreatePhoneInput!
    $condition: ModelPhoneConditionInput
  ) {
    createPhone(input: $input, condition: $condition) {
      id
      a360id
      countryCode
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePhone = /* GraphQL */ `
  mutation UpdatePhone(
    $input: UpdatePhoneInput!
    $condition: ModelPhoneConditionInput
  ) {
    updatePhone(input: $input, condition: $condition) {
      id
      a360id
      countryCode
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePhone = /* GraphQL */ `
  mutation DeletePhone(
    $input: DeletePhoneInput!
    $condition: ModelPhoneConditionInput
  ) {
    deletePhone(input: $input, condition: $condition) {
      id
      a360id
      countryCode
      number
      type
      createdAt
      updatedAt
    }
  }
`;
