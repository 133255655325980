import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/images/*',
    redirect: '/error'
  },
  {
    path: '/error',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/:id',
    name: 'Advisor',
    component: () => import('../views/Advisor.vue'),
    beforeEnter: requireAuth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  let sub = global.location.host
  if (sub.includes("mers")) {
    Store.commit("user/setMERSBranded", true)
  }
  next()
})

async function requireAuth(to, from, next) {
  if (Store.getters["user/isAuthenticated"] && (Store.getters["user/userGroup"] === "AdvisorProfile") || Store.getters["user/userGroup"] === "SystemAdmin") {
    next()
  } else {
    if (await Store.dispatch("user/checkUser") && (Store.getters["user/userGroup"] === "AdvisorProfile") || Store.getters["user/userGroup"] === "SystemAdmin") {
      next()
    } else {
      next("/")
    }
  }
}
// async function requireSystemAdminAuth(to, from, next) {
//   if (Store.getters["user/isAuthenticated"] && Store.getters["user/userGroup"] === "SystemAdmin") {
//     next()
//   } else {
//     if (await Store.dispatch("user/checkUser") && Store.getters["user/userGroup"] === "SystemAdmin") {
//       next()
//     } else {
//       next("/")
//     }
//   }
// }

export default router