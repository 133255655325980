<template>
  <div v-if="user">
    <div v-if="!authorized">
      <p>Please ask admin for access rights</p>
    </div>
    <div v-else>
      <dashboard />
    </div>
  </div>
</template>

<script>
// import { AmplifyEventBus } from "aws-amplify-vue";
import { mapState, mapGetters } from "vuex";
import Dashboard from "@/components/Dashboard.vue";
export default {
  name: "Home",
  components: {
    Dashboard
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["user", "state", "confirmed"]),
    ...mapGetters("user", ["authorized"])
  },
  async created() {
    await this.$store.dispatch("user/checkUser");
  },
  methods: {
    formatName(name) {
      let [last, first] = name.split(",");
      return `${first} ${last}`;
    },
    formatAccountName(name) {
      return;
    }
  }
};
</script>

<style lang="scss">
.v-select__selections {
  cursor: pointer;
}
</style>
